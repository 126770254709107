import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CijfersPageComponent } from './cijfers-page/cijfers-page.component';
import { HomePageComponent } from './home-page/home-page.component';
import { DrugsInfoPageComponent } from './drugs-info-page/drugs-info-page.component';
import { StopGebruikPageComponent } from './stop-gebruik-page/stop-gebruik-page.component';
import { BronPageComponent } from './bron-page/bron-page.component';

const routes: Routes = [
  { path: 'info', component: DrugsInfoPageComponent },
  { path: 'stoppen', component: StopGebruikPageComponent },
  { path: 'cijfers', component: CijfersPageComponent },
  { path: 'home', component: HomePageComponent},
  { path: 'bron', component: BronPageComponent},
  { path: '**',
    redirectTo: '/home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
