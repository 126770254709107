import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cijfers-page',
  templateUrl: './cijfers-page.component.html',
  styleUrls: ['./cijfers-page.component.css']
})
export class CijfersPageComponent implements OnInit {
  gdchart = {
    'title': 'Geslacht deelnemers',
    'type': 'PieChart',
    'data': [
      ['Man', 51],
      ['Vrouw', 49]
    ],
    'columnNames': ['Geslacht', 'Percentage'],
    'options': {}
  };

  lekdchart = {
    'title': 'Leeftijd eerste keer drugsgebruik',
    'type': 'ColumnChart',
    'data': [
      ['10', 1],
      ['12', 2],
      ['13', 8],
      ['14', 11],
      ['15', 7],
      ['16', 3],
      ['17', 3],
      ['18', 5],
      ['19', 1],
      ['24', 1],
      ['25', 1],
      ['nvt', 37],
    ],
    'columnNames': ['Leeftijd', 'Aantal'],
    'options': {}
  };

  sdchart = {
    'title': 'Scholing deelnemers',
    'type': 'PieChart',
    'data': [
      ['vmbo', 44],
      ['havo', 21],
      ['vwo', 10],
      ['anders', 25]
    ],
    'columnNames': ['Scholing', 'Percentage'],
    'options': {}
  };

  constructor() { }

  ngOnInit() {
  }

}
