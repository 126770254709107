import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet/dist/leaflet.css';

declare var require: any;

@Component({
  selector: 'app-stop-gebruik-page',
  templateUrl: './stop-gebruik-page.component.html',
  styleUrls: ['./stop-gebruik-page.component.css']
})
export class StopGebruikPageComponent implements OnInit {
  @ViewChild('cardBody', {static: false}) cardBody: ElementRef;

  private map;

  questions = [
    {
      question: "Gebruikt u drugs?",
      y: 1,
      n: false
    },
    {
      question: "Verbergt u uw drugsgebruik voor familie en vrienden?",
      y: 2,
      n: 2
    },
    {
      question: "Bent u geestelijk alleen maar bezig met drugs?",
      y: 3,
      n: false
    },
    {
      question: "Kunt u lichamelijk zonder drugs?",
      y: false,
      n: true
    },
  ];
  currentQuestion: number;
  
  constructor() {
    this.currentQuestion = 0;
  }

  ngOnInit() {
    this.initializeMap()
  }

  initializeMap(): void {
    L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png')
    });

    this.map = L.map('map', {
      center: [ 52.207230, 4.418420 ],
      zoom: 14
    })

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 16,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.map);


    L.marker([52.207230, 4.418420]).addTo(this.map)
      .bindPopup('<a href="https://www.brijderjeugd.nl/" target="_blank">Brijder Jeugd Behandeling</br>Schimmelpenninckstraat 12, 2221 EP Katwijk aan Zee</a>');

    L.marker([52.206030, 4.415850]).addTo(this.map)
      .bindPopup('<a href="https://www.debrughelpt.nl/" target="_blank">Stichting De Brug</br>Schaepmanstraat 1, 2221 ER Katwijk aan Zee</a>');
  }

  answer(bool: boolean): void {
    const q = this.questions[this.currentQuestion];
    const n = (bool)? q.y : q.n;
    if (typeof n === "boolean" ) {
      if (n) {
        this.cardBody.nativeElement.innerHTML = "U bent verslaafd. Besef dat u een probleem hebt en zoek hulp. Verschillende verslavingszorgen kunt u hieronder vinden op de kaart.";
      } else {
        this.cardBody.nativeElement.innerHTML = "U bent niet verslaafd. Bent u toch benieuwd naar de locaties van de verschillende verslavingszorgen in Katwijk? Deze kunt u vinden op de onderstaande kaart.";
      } 
    } else {
      this.currentQuestion = n;
    }
  }

}
