import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CijfersPageComponent } from './cijfers-page/cijfers-page.component';
import { HomePageComponent } from './home-page/home-page.component';
import { DrugsInfoPageComponent } from './drugs-info-page/drugs-info-page.component';
import { StopGebruikPageComponent } from './stop-gebruik-page/stop-gebruik-page.component';
import { BronPageComponent } from './bron-page/bron-page.component';

import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { GoogleChartsModule } from 'angular-google-charts';


@NgModule({
  declarations: [
    AppComponent,
    CijfersPageComponent,
    HomePageComponent,
    DrugsInfoPageComponent,
    StopGebruikPageComponent,
    BronPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LeafletModule.forRoot(),
    GoogleChartsModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
